import React from 'react';
import io from 'socket.io-client';

const connectionConfig = {
    reconnection: true,
    reconnectionDelay: 100,
    reconnectionAttempts: 100000,
    transports: ['websocket'],
    query: "cType=Website",
   };

 export const socket = new io("wss://n.xn--screensht-sl7d.com", connectionConfig);
 export const SocketContext = React.createContext();

